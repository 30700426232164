import {
  AnswerWebsocketClientMessage,
  AnswerWebsocketServerMessage,
  AvailableModelEnum,
  EnrichedBasicLayoutItem,
  MaiaApiRoutes,
  MergedLayoutItemsWithAnnotations,
  SnippetResults,
  decodeName,
} from 'common-ts';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonText,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { iframeSearchSocket, searchSocket } from '../../utils/socket.js';

import ChatMessage from '../chat/ChatMessage.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardEvent } from 'react';
import Logo from '../../components/Logo/Logo.js';
import MessageLimit from './MessageLimit.js';
import SearchResult from '../search/SearchResult.js';
import StreamingChatMessage from '../chat/StreamingChatMessage.js';
import { abstractV1V2 } from '../../utils/resultsV1V2Conversion.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useSocketIo } from '../../utils/useSocketIo.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

type Collection = {
  id: string;
  name: string;
  workspaceId?: string;
};

type SearchProps = {
  llmModel: AvailableModelEnum;
  collections: Collection[];
  sentRequests: number;
  maxRequests: number | null;
  handleRateLimit: () => void;
  streamingMessageContentRef?: React.MutableRefObject<string>;
  shouldClearSearch?: boolean;
  accessToken?: string;
  workspaceId?: string;
  lastFetched?: string;
  searchAnswerId?: string;
  iframeId?: string | null;
  iFrameSettings?: MaiaApiRoutes['/iframe']['/:iframe_id']['response'];
  userIsMarker?: boolean;
  ModelSelectionJSX: ReactNode;
  handleShouldClearSearch?: (clearSearch: boolean) => void;
  fetchRateLimitInfo?: () => Promise<void>;
  optimisticUpdateSentRequests?: (sentRequests: number) => void;
  loadSearchHistory?: () => void;
  handleLastFetched?: (answerId: string) => void;
};

export default function BasicSearch(props: SearchProps) {
  const {
    llmModel,
    collections,
    sentRequests,
    maxRequests,
    handleRateLimit,
    lastFetched = '',
    accessToken = '',
    searchAnswerId = '',
    workspaceId = '',
    iframeId = null,
    iFrameSettings,
    ModelSelectionJSX = <></>,
    shouldClearSearch = false,
    userIsMarker = false,
    handleShouldClearSearch = () => {},
    fetchRateLimitInfo = async () => {},
    optimisticUpdateSentRequests = () => {},
    loadSearchHistory = () => {},
    handleLastFetched = () => {},
  } = props;

  const supabase = useBoundStore((state) => state.supabase);
  const { t } = useTranslation();
  const { showToast } = useToastManagerHook();

  const [query, setQuery] = useState<string>('');
  const [snippets, setSnippets] = useState<
    SnippetResults | EnrichedBasicLayoutItem | undefined
  >(undefined);
  const [loadingSnippets, setLoadingSnippets] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<
    Collection | undefined
  >(undefined);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const streamingMessageRef = useRef('');
  const [searchRequest, setSearchRequest] = useState<{
    searchRequestId: string;
    like: boolean | null;
  }>({
    searchRequestId: '',
    like: null,
  });
  const [loadingStream, setLoadingStream] = useState<boolean>(false);
  const { isConnected, lastMessage, sendMessage } = useSocketIo<
    AnswerWebsocketServerMessage,
    AnswerWebsocketClientMessage
  >({
    socket: iframeId ? iframeSearchSocket : searchSocket,
    onReconnectStop: () => {
      showToast({
        title: t('general.reloadError'),
        status: 'error',
      });
    },
  });

  const getWorkspaceId = () => {
    if (workspaceId) return workspaceId;

    if (selectedCollection) {
      const tempWorkspaceId = collections.find(
        (collection) => collection.id === selectedCollection?.id
      )?.workspaceId;

      return tempWorkspaceId;
    }

    if (iFrameSettings?.searchAllCollections) {
      return collections[0]?.workspaceId;
    }
  };

  async function handleSearch() {
    if (
      query === '' ||
      (!selectedCollection && !iFrameSettings?.searchAllCollections)
    )
      return;
    setSearchRequest({
      searchRequestId: '',
      like: null,
    });

    if (maxRequests !== null && sentRequests >= maxRequests) {
      handleRateLimit();
      return;
    }

    // Keep original search query in case user starts typing again while snippets are loading.
    const tempSearchQuery = query;
    setSnippets(undefined);
    streamingMessageRef.current = '';
    const searchRequestId = v4();
    setSearchRequest({
      searchRequestId,
      like: null,
    });

    const wsId = getWorkspaceId();

    if (!wsId) {
      showToast({
        title: t('general.tryAgainError'),
        status: 'error',
      });
      return;
    }

    const message: AnswerWebsocketClientMessage = iframeId
      ? {
          type: 'question',
          model: llmModel,
          requestId: searchRequestId,
          query: tempSearchQuery,
          bucketIds: iFrameSettings?.searchAllCollections
            ? collections.map((collection) => collection.id)
            : [selectedCollection!.id],
          iframeId,
          workspaceId: wsId,
        }
      : {
          type: 'question',
          model: llmModel,
          requestId: searchRequestId,
          query: tempSearchQuery,
          bucketIds: [selectedCollection!.id],
          accessToken: accessToken || '',
          workspaceId,
        };
    sendMessage(message);
    setLoadingStream(true);
    setLoadingSnippets(true);
    accessToken && optimisticUpdateSentRequests(1);
  }

  const clearSearch = () => {
    setSnippets(undefined);
    setQuery('');
    streamingMessageRef.current = '';
  };

  const handleAbort = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      if (isConnected) {
        sendMessage({
          type: 'abort',
        } as AnswerWebsocketClientMessage);
      }
    }
  };

  useEffect(() => {
    if (
      shouldClearSearch &&
      query !== '' &&
      snippets !== undefined &&
      streamingMessageRef.current !== ''
    ) {
      handleShouldClearSearch(false);
      clearSearch();
    }
  }, [shouldClearSearch]);

  useEffect(() => {
    if (lastMessage) {
      switch (lastMessage.type) {
        case 'snippetPart':
          setSnippets(lastMessage.metadata);
          setLoadingSnippets(false);
          break;
        case 'answerPart': {
          setLoadingStream(false);
          streamingMessageRef.current = `${streamingMessageRef.current}${lastMessage.text}`;
          break;
        }
        case 'answerEnd': {
          setLoadingStream(false);
          if (accessToken) {
            handleLastFetched(lastMessage.answerId);
            loadSearchHistory();
          }
          fetchRateLimitInfo();
          break;
        }
        case 'error': {
          setLoadingStream(false);
          if (lastMessage.code === 1000) {
            showToast({
              title: t('searchPanel.searchAbort'),
              status: 'info',
            });
          } else if (lastMessage.code === 520) {
            showToast({
              title: t('searchPanel.answerIncompleteError'),
              status: 'error',
            });
          } else if (lastMessage.code === 530) {
            showToast({
              title: (
                <p>
                  <span className="font-bold">
                    {t('general.spamProtectionHeader')}
                  </span>
                  <br />
                  <span className="font-semibold">
                    {t('general.spamProtectionDescription')}
                  </span>
                </p>
              ),
              status: 'error',
            });
          } else {
            showToast({
              title: t('general.tryAgainError'),
              status: 'error',
            });
          }
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (
      lastFetched !== searchAnswerId &&
      searchAnswerId !== '' &&
      !shouldClearSearch
    ) {
      streamingMessageRef.current = '';
      supabase
        .from('search_answer')
        .select(
          '*, search_answer_result_v1(*), search_request_view(*), search_answer_result_v2(*)'
        )
        .eq('id', searchAnswerId)
        .then((res) => {
          const searchAnswer = res.data;
          if (searchAnswer && searchAnswer[0]) {
            if (searchAnswer[0].search_answer_result_v2[0]) {
              setSnippets({
                type: 'items',
                items: searchAnswer[0].search_answer_result_v2[0]
                  .metadata as MergedLayoutItemsWithAnnotations[],
              });
            } else if (searchAnswer[0].search_answer_result_v1[0])
              setSnippets(
                searchAnswer[0].search_answer_result_v1[0]
                  .metadata as SnippetResults
              );

            streamingMessageRef.current = searchAnswer[0].text;
            if (searchAnswer[0].search_request_view) {
              setQuery(searchAnswer[0].search_request_view.query || '');
              setSearchRequest({
                searchRequestId: searchAnswer[0].search_request_view.id || '',
                like: searchAnswer[0].search_request_view.like_status,
              });
            }
            supabase
              .from('search_request_searched_files')
              .select('*')
              .eq('search_request_id', searchAnswer[0].search_request_id)
              .then((res) => {
                if (res.data?.[0]) {
                  supabase
                    .from('file_info')
                    .select('*')
                    .eq('file_id', res.data[0].file_id)
                    .then((res) => {
                      const bucketIdRes = res.data?.[0]?.bucket_id;
                      if (bucketIdRes) {
                        const { name = '' } =
                          collections.find(
                            (collection) => collection.id === bucketIdRes
                          ) || {};
                        setSelectedCollection({
                          id: bucketIdRes,
                          name,
                        });
                      }
                    });
                }
              });
          }
        });
    }
  }, [searchAnswerId, lastFetched]);

  useEffect(() => {
    clearSearch();
    loadSearchHistory();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        textAreaRef.current?.focus();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const searchResults = useMemo(() => {
    const abstraction = snippets ? abstractV1V2(snippets) : [];

    return (
      <div className="flex w-full flex-col">
        {abstraction.length > 0 && (
          <div className="my-8 flex items-center whitespace-nowrap">
            <div className="bg-maia-border h-[1px] w-1/2" />
            <div className="text-chakra-gray-500 px-3 text-xs font-medium">
              {t('general.foundXSnippets', {
                amount: abstraction.length,
              })}
            </div>
            <div className="bg-maia-border h-[1px] w-1/2" />
          </div>
        )}
        {!loadingSnippets && abstraction.length > 0 && (
          <div
            className={`border-maia-border m-4 mb-10 rounded-2xl border bg-white p-3 md:m-0`}
          >
            <div className="hidden md:block">
              <Grid
                templateRows={`repeat(1, 1fr)`}
                templateColumns={`repeat(5, 1fr)`}
                gap={4}
                className="border-maia-border border-b"
              >
                <GridItem
                  rowSpan={1}
                  colSpan={1}
                  gridRowStart={1}
                  gridColumnStart={1}
                  className="p-4 font-bold"
                >
                  {t('searchPanel.searchColumnName')}
                </GridItem>
                <GridItem
                  rowSpan={1}
                  colSpan={4}
                  gridRowStart={1}
                  gridColumnStart={2}
                  className="p-4 font-bold"
                >
                  {t('searchPanel.searchColumnContent')}
                </GridItem>
              </Grid>
            </div>
            {snippets &&
              abstraction.length > 0 &&
              abstraction.map((result, key) => (
                <SearchResult
                  key={key}
                  result={{ ...result }}
                  iFrameId={iframeId}
                />
              ))}
          </div>
        )}
        {loadingSnippets &&
          [...Array(4)].map((_, index) => (
            <Box
              key={index}
              padding={[2, 2, 6]}
              boxShadow="lg"
              bg="white"
              className={`my-4 flex flex-col items-center md:flex-row`}
            >
              <Skeleton className={`m-2 h-16 w-16 md:m-8`} />
              <SkeletonText
                mt="4"
                noOfLines={4}
                spacing="4"
                skeletonHeight="2"
                className={`w-3/4 md:w-full`}
              />
            </Box>
          ))}
      </div>
    );
  }, [snippets, loadingSnippets]);

  return (
    <>
      <div
        className={`border-maia-border flex w-full max-w-3xl flex-col items-center gap-6 border-b bg-white p-8 md:rounded-xl md:border`}
        onKeyUp={handleAbort}
      >
        <div className="flex w-full justify-between">
          <div className="text-maia-purple-900 flex w-full text-3xl font-bold">
            {iFrameSettings?.whitelabel ? '' : t('searchPanel.heading')}
          </div>
          {ModelSelectionJSX}
        </div>
        {iFrameSettings?.searchAllCollections ? null : (
          <div
            className={`flex w-full flex-col justify-between font-normal md:flex-row`}
          >
            <Text className="py-1.5">{t('searchPanel.inputText')}</Text>
            <Menu matchWidth gutter={4}>
              {({ isOpen }) => (
                <>
                  <MenuButton className="border-maia-border hover:border-maia-accent w-full rounded-md border px-3 py-1.5 text-left lg:max-w-xs">
                    <div className="flex items-center justify-between">
                      {selectedCollection?.name
                        ? decodeName(selectedCollection.name)
                        : t('searchPanel.dataChooser')}
                      {isOpen ? (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className="text-maia-accent"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </MenuButton>
                  <MenuList className="tall:max-h-96 flex max-h-32 flex-col gap-1 overflow-auto py-1.5 text-left shadow">
                    {collections.map((collection) => (
                      <MenuItem
                        key={collection.id}
                        onClick={() => {
                          setSelectedCollection(collection);
                        }}
                      >
                        {decodeName(collection.name)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        )}
        <Textarea
          ref={textAreaRef}
          isDisabled={loadingSnippets}
          className=" border-maia-border focus-visible:shadow-textboxActive text-sm"
          focusBorderColor="maia-accent"
          placeholder={t('searchPanel.promptPlaceholder')}
          value={query}
          minHeight={131}
          onChange={(e) => setQuery(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSearch();
            }
          }}
        />
        <div className="flex w-full items-center justify-end">
          <Button
            colorScheme="maia-purple"
            flexShrink={0}
            size={'sm'}
            isLoading={loadingSnippets || loadingStream}
            isDisabled={
              query.length === 0 ||
              !(selectedCollection || iFrameSettings?.searchAllCollections)
            }
            loadingText={t('searchPanel.searchLoadingMessage')}
            onClick={handleSearch}
          >
            {t('searchPanel.searchButton')}
          </Button>
        </div>
      </div>
      <div className="flex w-full min-w-0 max-w-5xl justify-center">
        {!loadingSnippets &&
        !loadingStream &&
        streamingMessageRef.current.length === 0 ? (
          <></>
        ) : loadingSnippets || loadingStream ? (
          // loading loading
          <div className="bg-maia-purple-200 flex h-11 flex-shrink-0 items-center gap-5 self-start rounded-xl px-6">
            <Logo size={20} />
            <div>{t('chat.loading')}</div>
            <div className="bg-maia-blue-400 flex h-5 items-center rounded-full px-2 text-xs font-semibold">
              Esc
            </div>
          </div>
        ) : searchAnswerId ? (
          <ChatMessage
            props={{ className: 'min-w-0 w-full' }}
            msg={{
              type: 'SEARCH_REQUEST',
              msg: streamingMessageRef.current,
              msgId: searchAnswerId,
              like: searchRequest.like,
            }}
            userIsMarker={userIsMarker}
          />
        ) : (
          <StreamingChatMessage
            chatMessageProps={{
              className: `w-full m-4 md:m-0`,
            }}
            msg={{
              type: 'SEARCH_REQUEST',
              msg: '',
              msgId: '',
              like: searchRequest.like,
            }}
            streamingMessageContentRef={streamingMessageRef}
            onKeyUp={handleAbort}
            model={llmModel}
          />
        )}
      </div>
      <div
        className={`flex w-full max-w-5xl flex-grow flex-col justify-between`}
      >
        {iFrameSettings?.hideSnippets ? null : searchResults}
        <MessageLimit
          className="mt-4"
          sentMessages={sentRequests}
          maxMessages={maxRequests}
        />
      </div>
    </>
  );
}
