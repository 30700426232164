import { Button, Divider, Skeleton, Text } from '@chakra-ui/react';
import {
  HistoryItemCategory,
  determineHistoryItemCategory,
  getCategoryTranslation,
} from '../../utils/historyItemsCategories';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES, zeros } from 'common-ts';
import { useEffect, useState } from 'react';

import BasicSearch from './BasicSearch.js';
import { ChangeLlm } from '../../components/ChangeLlm.js';
import DeleteModal from '../../components/DeleteModal.js';
import { FREE_USER_REQUEST_LIMT } from '../../utils/userPlan.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HistoryItem from '../../components/HistoryItem.js';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
import MaiaLayout from '../../components/MaiaLayout.js';
import MaiaMenuPanel from '../../components/menu/MaiaMenuPanel.js';
import { captureException } from '@sentry/react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { getLlmModel } from '../../utils/localStorage.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

type SearchItem = {
  created_at: string;
  deleted_at: string | null;
  id: string;
  search_request_id: string;
  text: string;
  topic: string;
  user_id: string | null;
  category: HistoryItemCategory;
};

export default function Search() {
  const { searchAnswerId } = useTypedParams(ROUTES.HOME.SEARCH);
  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToastManagerHook();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const accessToken = useBoundStore((state) => state.accessToken);
  const sentRequests = useBoundStore((state) => state.sentRequests);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const userIsMarker = useBoundStore((state) => state.userIsMarker);
  const optimisticUpdateSentRequests = useBoundStore(
    (state) => state.optimisticUpdateSentRequests
  );
  const [clearSearch, setClearSearch] = useState(false);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [maxMessages, setMaxMessages] = useState<number | null>(null);
  const [isMessageLimitAlertOpen, setIsMessageLimitAlertOpen] =
    useState<boolean>(false);
  const [lastFetched, setLastFetched] = useState('');
  const [searchHistoryLoading, setSearchHistoryLoading] =
    useState<boolean>(true);
  const [searchHistory, setSearchHistory] = useState<SearchItem[]>([]);
  const [llmModel, setLlmModel] = useState(
    getLlmModel(workspaceLicenseType ?? undefined)
  );

  const [deleteModal, setDeleteModal] = useState<{
    id: string;
    name: string | null;
  }>();

  const SearchCategoriesToDisplay = (): HistoryItemCategory[] => {
    return [
      HistoryItemCategory.TODAY,
      HistoryItemCategory.YESTERDAY,
      HistoryItemCategory.LAST_7_DAYS,
      HistoryItemCategory.LAST_30_DAYS,
      HistoryItemCategory.OLDER_THAN_A_MONTH,
    ];
  };

  useEffect(() => {
    setMaxMessages(
      workspaceLicenseType === 'FREE' ? FREE_USER_REQUEST_LIMT : null
    );
  }, [workspaceLicenseType]);

  useEffect(() => {
    if (searchAnswerId && searchAnswerId !== lastFetched) {
      setLastFetched(searchAnswerId);
    }
  }, [searchAnswerId]);

  /**
   * Handles updating the last fetched ID and triggers loading of search history.
   * @param id - The ID of the last fetched search item.
   */
  const handleLastFetched = (id: string) => {
    setLastFetched(id);
    loadSearchHistory();
    navigate(ROUTES.HOME.SEARCH.buildPath({ workspaceId, searchAnswerId: id }));
  };

  /**
   * Loads the search history from the server and categorizes each item based on its creation date.
   */
  function loadSearchHistory() {
    supabase
      .from('search_answer')
      .select()
      .eq('workspace_id', workspaceId)
      .order('created_at', { ascending: false })
      .then((result) => {
        if (result.data) {
          const categorizedSearchHistory = result.data.map((item) => ({
            ...item,
            category: determineHistoryItemCategory(new Date(item.created_at)),
          }));
          setSearchHistory(categorizedSearchHistory);
        }
        setSearchHistoryLoading(false);
      });
  }

  /**
   * Handles the renaming of a search item.
   * @param name - The new name for the search item.
   * @returns A promise that resolves to the result of the update operation.
   */
  async function handleRename(name: string) {
    return supabase
      .from('search_answer')
      .update({ topic: name })
      .eq('id', searchAnswerId || '');
  }

  /**
   * Sets the state to clear the search.
   * @param val - The value to set for the `clearSearch` state.
   */
  const handleClearSearch = (val: boolean) => {
    setClearSearch(val);
  };

  /**
   * Handles initiating a new search by resetting relevant states.
   */
  function handleNewSearch() {
    navigate('.');
    setLastFetched('');
    handleClearSearch(true);
  }

  /**
   * Renders the search history for a given category.
   * @param category - The category for which to render the search history.
   * @returns The JSX element representing the search history for the category, or `null` if there are no items.
   */
  const renderSearchHistoryCategory = (category: HistoryItemCategory) => {
    const filteredSearchHistory = searchHistory.filter(
      (item) => item.category === category
    );

    return filteredSearchHistory.length > 0 ? (
      <>
        <Text className="font-medium">
          {getCategoryTranslation(category, t)}
        </Text>
        {filteredSearchHistory.map((item) => (
          <div key={item.id}>
            <Link
              to={ROUTES.HOME.SEARCH.buildPath({
                workspaceId,
                searchAnswerId: item.id,
              })}
            >
              <HistoryItem
                chatId={item.search_request_id}
                chatTopic={item.topic ?? undefined}
                defaultTopic={t('searchPanel.undefinedChatTopic')}
                selected={searchAnswerId === item.id}
                onDeleteClick={() => {
                  setDeleteModal({ id: item.id, name: item.topic });
                }}
                onRename={(name) => handleRename(name)}
                domElementProps={{ onClick: () => setSidePanelOpen(false) }}
              />
            </Link>
          </div>
        ))}
        <Divider className="pb-1 pt-1" />
      </>
    ) : null;
  };

  return (
    <MaiaLayout
      sidePanelContent={
        <MaiaMenuPanel selectedNavigation="SEARCH">
          <div className="flex min-h-0 flex-col gap-3 px-4 pt-3">
            <Button
              className="text-maia-text-dark flex-shrink-0 text-sm"
              size="md"
              isDisabled={!searchAnswerId}
              leftIcon={
                <FontAwesomeIcon className="text-maia-accent" icon={faPlus} />
              }
              onClick={handleNewSearch}
            >
              {t('searchPanel.newSearchButton')}
            </Button>
            <Divider />
            <div className="-mr-4 flex min-h-0 flex-col gap-2 overflow-auto pr-4">
              {searchHistoryLoading
                ? zeros(4).map((_, index) => (
                    <Skeleton className="h-11" key={index} />
                  ))
                : SearchCategoriesToDisplay().map((category) => (
                    <div key={category}>
                      {renderSearchHistoryCategory(category)}
                    </div>
                  ))}
            </div>
          </div>
          <DeleteModal
            title={t('searchPanel.deleteModalTitle')}
            subtitle={t('searchPanel.deleteModalSubtitle')}
            isOpen={!!deleteModal}
            onConfirm={async () => {
              if (deleteModal) {
                const res = await supabase
                  .from('search_answer')
                  .delete()
                  .eq('id', deleteModal.id || '');
                if (res.error) {
                  captureException(res.error);
                  showToast({
                    title: t('general.reloadError'),
                    status: 'error',
                  });
                }
                if (res.status === 204) {
                  const copy = deleteModal.name;
                  showToast({
                    title: t('general.successfullyDeleted', {
                      name: `- ${
                        copy || t('searchPanel.undefinedChatTopic')
                      } -`,
                    }),
                    status: 'success',
                  });
                }
                setDeleteModal(undefined);
                loadSearchHistory();
              }
            }}
            onClose={() => setDeleteModal(undefined)}
          />
        </MaiaMenuPanel>
      }
      mainPanelContent={
        <div
          className={`flex h-full w-full flex-col items-center gap-y-6 bg-neutral-50 pb-0 pt-0 md:pt-24`}
        >
          <BasicSearch
            ModelSelectionJSX={
              <ChangeLlm
                onChange={() =>
                  setLlmModel(getLlmModel(workspaceLicenseType ?? undefined))
                }
              />
            }
            accessToken={accessToken}
            workspaceId={workspaceId}
            searchAnswerId={searchAnswerId}
            llmModel={llmModel}
            collections={extendedBuckets.map((bucket) => ({
              id: bucket.id,
              name: bucket.display_name,
            }))}
            sentRequests={sentRequests}
            maxRequests={maxMessages}
            userIsMarker={userIsMarker}
            handleRateLimit={() => setIsMessageLimitAlertOpen(true)}
            optimisticUpdateSentRequests={optimisticUpdateSentRequests}
            loadSearchHistory={loadSearchHistory}
            handleLastFetched={handleLastFetched}
            lastFetched={lastFetched}
            shouldClearSearch={clearSearch}
            handleShouldClearSearch={handleClearSearch}
          />
          <MaiaAlertDialogWithUpgradeButton
            isOpen={isMessageLimitAlertOpen}
            onClose={() => {
              setIsMessageLimitAlertOpen(false);
            }}
            AlertDialogHeaderText={t('searchPanel.messageLimitReached')}
            AlertDialogBodyText={t('searchPanel.messageLimitReachedBody')}
          />
        </div>
      }
      sidePanelOpenMobile={sidePanelOpen}
      onCloseSidePanelMobile={() => setSidePanelOpen(false)}
      onOpenSidePanelMobile={() => setSidePanelOpen(true)}
    />
  );
}
