import { faWarning, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const MAINTENANCE_SEEN_KEY = 'maintenance_seen';

function MaintenanceBanner() {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const maintentanceSeen = localStorage.getItem(MAINTENANCE_SEEN_KEY);
    setShowBanner(!maintentanceSeen);
  }, []);

  function handleHideBanner() {
    localStorage.setItem(MAINTENANCE_SEEN_KEY, 'true');
    setShowBanner(false);
  }

  if (showBanner) {
    return (
      <div className="absolute z-30 flex w-full items-center justify-between gap-4 border-b-2 border-yellow-300 bg-yellow-50 px-8 py-3">
        <FontAwesomeIcon
          icon={faWarning}
          className="text-2xl text-yellow-400"
        />
        <div>{t('general.maintenance')}</div>
        <IconButton
          onClick={handleHideBanner}
          variant={'ghost'}
          icon={<FontAwesomeIcon icon={faXmark} />}
          aria-label="close"
        />
      </div>
    );
  } else {
    return null;
  }
}

export default MaintenanceBanner;
